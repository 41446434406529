<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 700px;'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-w-750px sl-font-size-16px sl-mtb-10px'>Energy Star Data Transfer</div>
        <div class='sl-mb-20px sl-flex-justify-content-end sl-flex-align-items-center'>
          <CloseIcon class='figma-icon sl-ml-10px' @click="$emit('closeModal')"/>
        </div>
      </div>
      <div class='sl-border-t-2px-primary-blue sl-ptb-20px'>
        <div class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <button @click='updateAllProperties'
            class='sl-simple-outline-btn sl-w-100px'
            :class="{ 'sl-simple-outline-btn-disabled':updateRunning || currentPropertySyncing}">
            Update
          </button>
          <span class='sl-ml-20px sl-font-size-14px'>
            Update all properties with new utilty data and property metrics from ENERGY STAR<sup>&#174</sup>.
          </span>
        </div>
        <div class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <button @click='fetchNewProperties'
                  class='sl-simple-outline-btn sl-w-100px'
                  :class="{ 'sl-simple-outline-btn-disabled':updateRunning || currentPropertySyncing }">
                  Add
          </button>
          <span class='sl-ml-20px sl-font-size-14px'>
            Add new properties from the Energy Star<sup>&#174</sup> account that are not yet in SiteLynx.
          </span>
        </div>
      </div>
      <br>
      <!-- Data Response Tables -->
      <!-- Properties Updated -->
      <div v-if='updateRunning' class='sl-flex-align-items-center'>
        <span class='sl-white-space-no-wrap'>{{ updateText }}</span>
        <div  class='sl-spinning-wheel-20px sl-mlr-10px'></div>
      </div>
      <div v-if='showResponse'>
        <div v-if='noUpdatedProperties || noNewProperties'>
          <div v-if='showUpdatedPropertiesResponse' class='sl-font-size-14px'>
              All properties are up to date.
          </div>
          <div v-else-if='showNewPropertiesResponse' class='sl-font-size-14px'>
              No new properties found.
          </div>
        </div>
        <div v-else>
          <div class='sl-font-size-14px sl-mb-10px'>{{ responseHeader }}</div>
          <table class='sl-w-100per sl-generic-table-2'>
            <thead>
              <tr class='sl-border-t-2px-primary-blue'>
                <th class='sl-w-100px'>ID</th>
                <th class='sl-text-align-left'>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if='showUpdatedPropertiesResponse'>
              <tr v-for='property in updatedProperties'>
                <td class='sl-w-100px'>
                  <a :href="`/espm_properties/${property.id}`" target='blank'>{{ property.espm_api_property_id }}</a>
                </td>
                <td class='sl-text-align-left'>{{ property.name }}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody v-else-if='showNewPropertiesResponse'>
              <tr v-for='property in newProperties'>
                <td class='sl-w-100px'>
                  <a v-if='propertyAdded(property)' :href="`/espm_properties/${property.id}`" target='blank'>{{ property.api_id }}</a>
                  <span v-else>{{ property.api_id }}</span>
                </td>
                <td class='sl-text-align-left'>{{ property.name }}</td>
                <td class='sl-flex-justify-content-end'>
                  <div v-if='currentPropertySyncing === property.api_id'
                      class='sl-flex-align-items-center sl-flex-justify-content-end sl-h-28px'>
                    <span class='sl-white-space-no-wrap'>Syncing Property...</span>
                    <div  class='sl-spinning-wheel-20px sl-ml-10px'></div>
                  </div>
                  <div v-else-if='propertyAdded(property)'
                      class='sl-h-28px disabled-font sl-flex-align-items-center'>
                      Synced
                  </div>
                  <div v-else class='sl-tooltip sl-h-28px'>
                    <dataTransferIcon @click='syncNewProperty(property)'
                                      class='sl-mt-5px'
                                      :class="{ 'figma-icon': !currentPropertySyncing}"
                                      transform='scale(0.8)'/>
                    <span class='sl-tooltiptext'>Sync Property Data</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import CloseIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import dataTransferIcon from '../../../../../assets/images/icons/Icon-Data-Transfer_Charcoal-Dark-100_16.svg';

export default {
  components: {
    CloseIcon,
    dataTransferIcon,
  },

  data() {
    return {
      updateRunning: false,
      updatedProperties: [],
      newProperties: [],
      propertiesAdded: [],
      updateText: null,
      currentPropertySyncing: null,
      showResponse: false,
      showUpdatedPropertiesResponse: false,
      showNewPropertiesResponse: false,
      noUpdatedProperties: false,
      noNewProperties: false,
      responseHeader: null,
    };
  },

  methods: {
    async updateAllProperties() {
      this.hideReponseTables();
      this.updateText = 'Updating properties...';
      this.responseHeader = 'Updated Properties:';
      this.updateRunning = true;
      axios.post('/espm_properties/update_all_properties')
        .then((response) => {
          this.updatedProperties = response.data.properties_updated;
          this.noUpdatedProperties = this.updatedProperties.length === 0;
          this.showUpdatedPropertiesResponse = true;
          this.updateRunning = false;
          this.showResponse = true;
        })
        .catch(() => {
          this.updateRunning = false;
          alert('An error occurred while updating properties.');
        });
    },

    async fetchNewProperties() {
      this.hideReponseTables();
      this.updateText = 'Checking for new properties...';
      this.responseHeader = 'New Properties:';
      this.updateRunning = true;
      axios.get('/espm_properties/check_for_new_properties')
        .then((response) => {
          this.newProperties = response.data.new_properties;
          this.noNewProperties = this.newProperties.length === 0;
          this.showNewPropertiesResponse = true;
          this.updateRunning = false;
          this.showResponse = true;
        })
        .catch(() => {
          this.updateRunning = false;
          alert('An error occurred while checking for new properties.');
        });
    },

    async syncNewProperty(property) {
      this.currentPropertySyncing = property.api_id;
      await axios.post('/espm_properties/sync_new_property',
        {
          property_api_id: property.api_id,
        })
        .then((response) => {
          this.propertiesAdded.push(property.api_id);
          this.newProperties.find((p) => p.api_id === property.api_id).id = response.data.property_id;
          this.currentPropertySyncing = null;
        })
        .catch(() => {
          this.currentPropertySyncing = null;
          alert('An error occurred while syncing property data.');
        });
    },

    propertyAdded(property) {
      return this.propertiesAdded.includes(property.api_id);
    },

    hideReponseTables() {
      this.showUpdatedPropertiesResponse = false;
      this.noUpdatedProperties = false;
      this.showNewPropertiesResponse = false;
      this.noNewProperties = false;
      this.showResponse = false;
    },
  },
};
</script>
<style scoped>
  tbody tr:nth-child(odd) {
    background: var(--light-charcoal);
  }
</style>
