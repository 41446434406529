<template>
  <div v-if='dataReady && !primaryPathwaySelected && !secondaryPathwaySelected'
       class='sl-border-b-2px sl-font-size-14px sl-pt-20px sl-pb-20px sl-text-align-center'>
    No Energy Pathway Selected
  </div>
  <div v-if='dataReady'>
    <div class='sl-text-align-center sl-h-15px sl-mt-10px'>
      <span v-if='alertMessage' class='sl-partner-blue-text sl-bold-text'>{{ alertMessage }}</span>
      <span v-else style='color: red;'>{{ errorMessage }}</span>
    </div>
    <div v-if='primaryPathwaySelected'>
      <EnergyOffsetTable :pathwayName='primaryPathwayName'
                         :totalOffset='primaryTotalOffset'
                         :energyOffsets='primaryEnergyOffsets'
                         :offsetTotals='primaryOffsetTotals'
                         :years='years'
                         :category="'primary'"
                         @updateEnergyOffset='updateEnergyOffset'/>
    </div>
    <br>
    <div v-if='secondaryPathwaySelected'>
      <EnergyOffsetTable :pathwayName='secondaryPathwayName'
                         :totalOffset='secondaryTotalOffset'
                         :energyOffsets='secondaryEnergyOffsets'
                         :offsetTotals='secondaryOffsetTotals'
                         :years='years'
                         :category="'secondary'"
                         @updateEnergyOffset='updateEnergyOffset'/>
      </div>
  </div>
</template>
<script>
import axios from 'axios';
import EnergyOffsetTable from './EnergyOffsetTable.vue';

export default {
  components: {
    EnergyOffsetTable,
  },

  data() {
    return {
      baselineMetricId: null,
      baselineMetric: null,
      years: [],
      primaryTotalOffset: null,
      primaryEnergyOffsets: [],
      primaryOffsetTotals: {},
      primaryPathwayName: '',
      secondaryTotalOffset: null,
      secondaryEnergyOffsets: [],
      secondaryOffsetTotals: {},
      secondaryPathwayName: '',
      dataReady: false,
      alertMessage: '',
      errorMessage: '',
      primaryOffsetType: 'annual',
      secondaryOffsetType: 'annual',
      primaryPathwaySelected: true,
      secondaryPathwaySelected: false,
    };
  },

  mounted() {
    this.baselineMetricId = new URLSearchParams(window.location.search).get('baseline_metric_id');
    this.fetchEnergyOffsets();
  },

  methods: {
    async fetchEnergyOffsets() {
      await axios.get('/energy_offsets', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.baselineMetric = response.data.baseline_metric;
          this.primaryPathwaySelected = response.data.primary_pathway_selected;
          this.secondaryPathwaySelected = response.data.secondary_pathway_selected;
          this.primaryTotalOffset = response.data.primary_total_offset;
          this.primaryEnergyOffsets = response.data.primary_energy_offsets;
          this.primaryOffsetTotals = response.data.primary_offset_totals;
          this.primaryPathwayName = response.data.primary_pathway_name;
          this.secondaryTotalOffset = response.data.secondary_total_offset;
          this.secondaryEnergyOffsets = response.data.secondary_energy_offsets;
          this.secondaryOffsetTotals = response.data.secondary_offset_totals;
          this.secondaryPathwayName = response.data.secondary_pathway_name;
          this.years = response.data.years;
          this.dataReady = true;
        })
        .catch(() => {
          this.errorMessage = 'Error Fetching Data';
        });
    },

    async updateEnergyOffset(energyOffset, category, index = null) {
      await axios.put(`/energy_offsets/${energyOffset.id}`, {
        baseline_metric_id: this.baselineMetricId,
        price_per_unit: this.formatPrice(energyOffset),
        category,
      })
        .then((response) => {
          if (energyOffset.offset_type === 'total' && category === 'primary') {
            this.primaryTotalOffset = response.data.energy_offset;
          } else if (energyOffset.offset_type === 'total' && category === 'secondary') {
            this.secondaryTotalOffset = response.data.energy_offset;
          } else if (category === 'primary') {
            this.primaryEnergyOffsets[index] = response.data.energy_offset;
            this.primaryOffsetTotals = response.data.offset_totals;
          } else {
            this.secondaryEnergyOffsets[index] = response.data.energy_offset;
            this.secondaryOffsetTotals = response.data.offset_totals;
          }
          this.alertMessage = 'Table Saved';
          setTimeout(() => { this.alertMessage = ''; }, 1000);
        })
        .catch(() => {
          this.errorMessage = 'Error Saving Table';
        });
    },

    formatPrice(energyOffset) {
      // Replace non-numerical characters
      return energyOffset.price_per_unit.replace(/[^\d.-]/g, '');
    },
  },
};
</script>
