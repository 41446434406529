<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 600px;'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-w-750px sl-font-size-16px sl-mt-10px'>{{ `${tableTitle} Instructions` }}</div>
        <div class='sl-w-100px sl-mb-20px sl-flex-justify-content-end'>
          <CloseIcon class='figma-icon' @click="$emit('closeModal')" id='sl-history-log-close'/>
        </div>
      </div>
      <div class='sl-border-t-2px-primary-blue sl-border-b-2px sl-ptb-20px'>
        <!-- Add new table instructions in alphabetical order  -->
        <PcaImportInstructions
          v-if="['Bed Types', 'Commercial Tenant Spaces',
                 'multi hvac equipment', 'Observed Spaces'].includes(tableName)"
          :tableName="tableName"
          @import-complete="$emit('importComplete')"
        />
        <div v-else class='sl-font-size-16px disabled-font'>No instructions available.</div>
      </div>
    </div>
  </div>

</template>
<script>
import CloseIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import PcaImportInstructions from './PcaImportInstructions.vue';

export default {
  props: ['tableName', 'tableTitle'],

  components: {
    CloseIcon,
    PcaImportInstructions,
  },
};
</script>
<style>
  .report-table-instructions {
    line-height: 20px !important;
    color: #333E47;
    margin-top: 5px;
    font-size: 14px;
  }
</style>
