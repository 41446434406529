<template>
  <div v-for="(objects, category) in pricingFactors" :key="category.id" class="sl-flex-only sl-w-100per sl-mb-40px">
    <div class="sl-w-100px sl-ml-50px sl-flex-justify-content-end sl-mr-10px">{{ category }}</div>
    <div class="sl-border-l-2px-medium-charcoal sl-pl-20px">
      <table @input="$emit('service-changed')">
        <thead>
          <tr>
            <td style="width: 250px">Level</td>
            <td style="width: 570px">Description</td>
            <td style="width: 200px">Master %</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for='object in objects' class='sl-h-37px'>
            <td>
              <div class="pricing-factor-field-1">{{ object.level }}</div>
            </td>
            <td>
              <div class="pricing-factor-field-2">
                <span>{{ object.description }}</span>
              </div>
            </td>
            <td>
              <input class="pricing-factor-field-3 sl-disable-number-input-up-down-arrows"
                     @mouseenter="showInfoText(object)"
                     @mouseleave="hideInfoText(object)"
                     v-model="object.value"/>
              <div v-if='object.show_info_text' class="sl-pricing-factor-hover-text">
                <div>Updated By: {{ object.updated_by_formatted }}</div>
                <div>Updated At: {{ object.updated_at_formatted }}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pricingFactors: {
      type: Array,
      required: true,
    },
    timePeriodFee: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localTimePeriodFee: this.timePeriodFee,
    };
  },
  methods: {
    updateTimePeriodFee() {
      this.$emit('update-time-period-fee', this.localTimePeriodFee);
    },

    /* eslint-disable no-param-reassign */
    showInfoText(object) {
      object.hover = true;
      setTimeout(() => {
        if (object.hover) {
          object.show_info_text = true;
        }
      }, 1500);
    },

    hideInfoText(object) {
      object.hover = false;
      object.show_info_text = false;
    },
    /* eslint-enable no-param-reassign */
  },
};
</script>
