<template>
  <SpinningModal v-if='showLoadingModal'/>
  <div class='sl-w-100per sl-flex-justify-content-space-btwn sl-mb-10px'>
    <div class='sl-w-50px'><!-- Placeholder --></div>
    <ul>
      <li v-for='message in notice' class='sl-font-size-12px' style='color: red'>{{ message }}</li>
    </ul>
    <a class='sl-blue-link pl-circle-edit-btn sl-tooltip'>
      <plusIcon @click='addTenant' transform='scale(0.9)' class='figma-icon-blue links-center'/>
      <span class='sl-tooltiptext'>Create New Tenant</span>
    </a>
  </div>
  <table class='sl-w-100per sl-border-b-1px sl-mb-10px' style='border-collapse: collapse;'>
    <thead>
      <tr class='sl-border-tb-2px-primary-blue sl-h-42px sl-bold-text'>
        <td class='sl-w-300px sl-pl-10px'>Tenant Name</td>
        <td class='sl-w-200px sl-pl-5px'>Square Footage</td>
        <td class='sl-w-300px'>Meters</td>
        <td class='sl-w-100px'>Units</td>
        <td class='sl-w-200px'></td>
      </tr>
    </thead>
    <tbody v-if='tenants.length' class='sl-font-size-14px'>
      <tr v-for='(tenant, index) in tenants' class='sl-table__row--striped sl-h-42px'>
        <td class='sl-w-200px sl-pl-10px'>
          <input v-if='tenant.edit || tenant.new' v-model='tenant.name'
                 class='sl-border-1px-medium-charcoal sl-pl-5px sl-ptb-5px sl-w-270px'
                 type='text'
                 placeholder="Enter the tenant name here..."/>
          <span v-else>{{ tenant.name }}</span>
        </td>
        <td class='sl-w-220px sl-pl-5px'>
          <input v-if='tenant.edit || tenant.new' v-model='tenant.square_footage'
                 type='number'
                 class='sl-border-1px-medium-charcoal sl-pl-5px sl-ptb-5px
                        sl-w-150px sl-disable-number-input-up-down-arrows'
                        placeholder="0"/>
          <span v-else>{{ numberWithDelimiter(tenant.square_footage) }}</span>
        </td>
        <td class='sl-ptb-10px'>
          <div v-for='(meter, meterIndex) in tenant.meters' class='sl-ptb-5px sl-w-300px sl-flex-justify-content-space-btwn'>
            <a :href='`/espm_meters/${meter.id}`' class='sl-blue-link'>{{ meter.name }}</a>
            <a v-if='tenant.edit' @click='removeMeter(tenant, meter, meterIndex)' class='sl-blue-link pl-circle-edit-btn sl-tooltip'>
              <deleteIcon transform='scale(0.7)' class='figma-icon sl-mr-10px'/>
              <span class='sl-tooltiptext'>Remove Meter</span>
            </a>
          </div>
          <div v-if='tenant.edit || tenant.new'>
            <select v-if='newMeters.length' v-for='(newMeter, newMeterIndex) in newMeters'
                    v-model='this.newMeters[newMeterIndex]'
                    class='sl-border-1px-medium-charcoal sl-w-250px sl-ptb-5px sl-mb-10px'
                    style='display: block;'>
              <option :value=null selected style='color: #9F9EA4;'>Choose a Meter</option>
              <option v-for='meter in meters' :value='meter.id'>{{ meter.name }}</option>
            </select>
            <div @click='addMeter' class='sl-flex-align-items-center'>
              <a class='sl-blue-link pl-circle-edit-btn sl-bold-text'>
                <plusIcon class='figma-icon-blue links-center sl-mr-10px'/>
              </a>
              <a class='sl-blue-link sl-bold-text sl-font-size-12px'><span>Add Meter to Tenant</span></a>
            </div>
          </div>
        </td>
        <td>
          <div v-if='!tenant.edit' v-for='meter in tenant.meters' class='sl-ptb-5px'>{{ meter.unit_of_measure }}</div>
        </td>
        <td class='sl-flex-justify-content-end'>
          <div v-if='tenant.new' class='sl-flex-justify-content-space-btwn sl-ptb-10px'>
            <button @click='createTenant(tenant)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Save</button>
            <button @click='cancelCreate(index)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Cancel</button>
          </div>
          <div v-if='tenant.edit' class='sl-flex-justify-content-space-btwn sl-ptb-10px'>
            <button @click='updateTenant(tenant, index)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Save</button>
            <button @click='cancelUpdate(index)' class='sl-simple-outline-btn sl-w-85px sl-mr-10px sl-h-28px'>Cancel</button>
          </div>
          <div v-else-if='showEditButtons'
               class='sl-ptb-10px sl-w-60px
                      sl-flex-align-items-center sl-flex-justify-content-space-btwn'>
            <editIcon @click='editTenant(tenant)' class='figma-icon'/>
            <deleteIcon @click='deleteTenant(tenant, index)' transform='scale(0.8)' class='figma-icon'/>
          </div>
        </td>
      </tr>
      <tr class='sl-table__row--striped sl-h-42px sl-border-t-2px-primary-blue'>
      <td class='sl-w-200px sl-pl-10px'>Totals:</td>
      <td class='sl-w-200px sl-pl-5px'>
        {{ `${totalTenantSquareFootage()} of ${totalPropertySquareFootage()} sq. ft.` }}
      </td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
    </tbody>
  </table>
  <div v-if='!tenants.length' class='sl-font-size-14px sl-h-84px sl-flex-justify-content-and-align-items-center sl-border-b-1px'>
    {{ searchResultText }}
  </div>
</template>
<script>
import axios from 'axios';
import { cloneDeep as _cloneDeep } from 'lodash';
import SpinningModal from '../../../components/SpinningModal.vue';
import editIcon from '../../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';

export default {
  components: {
    SpinningModal,
    editIcon,
    deleteIcon,
    plusIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get('project_id');
    return {
      property: null,
      tenants: [],
      meters: [],
      projectId,
      newMeters: [],
      removeMeters: [],
      tenantBeforeEdit: null,
      showEditButtons: true,
      showLoadingModal: false,
      searchResultText: '',
      notice: [],
    };
  },

  async created() {
    this.fetchTenants();
  },

  methods: {
    async fetchTenants() {
      this.showLoadingModal = true;
      await axios.get('/espm_tenants/vue_index', {
        params: {
          project_id: this.projectId,
        },
      })
        .then((response) => {
          this.property = response.data.property;
          this.tenants = response.data.tenants;
          this.meters = response.data.meters;
          this.updateSearchResultText();
          setTimeout(() => { this.showLoadingModal = false; }, 500);
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('Something went wrong. Please reach out to admin@sitelynx.net for support.');
        });
    },

    async createTenant(tenant) {
      this.showLoadingModal = true;
      await axios.post('/espm_tenants', null, {
        params: {
          espm_tenant: tenant,
          new_meters: this.newMeters,
        },
      })
        .then((response) => {
          const lastIndex = this.tenants.length - 1;
          this.tenants[lastIndex] = response.data.tenant;
          this.newMeters = [];
          this.showEditButtons = true;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.notice = response.data.notice;
          setTimeout(() => { this.notice = []; }, 5000);
        })
        .catch((error) => {
          this.showEditButtons = true;
          this.showLoadingModal = false;
          this.notice = error.response.data.notice;
        });
    },

    async updateTenant(tenant, index) {
      this.showLoadingModal = true;
      await axios.put(`/espm_tenants/${tenant.id}`, null, {
        params: {
          espm_tenant: tenant,
          new_meters: this.newMeters,
          remove_meters: this.removeMeters,
        },
      })
        .then((response) => {
          this.tenants[index] = response.data.tenant;
          this.meters = response.data.meters;
          this.newMeters = [];
          this.removeMeters = [];
          this.showEditButtons = true;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.notice = response.data.notice;
          setTimeout(() => { this.notice = []; }, 5000);
        })
        .catch((error) => {
          this.showEditButtons = true;
          this.showLoadingModal = false;
          this.notice = error.response.data.notice;
        });
    },

    async deleteTenant(tenant, index) {
      if (window.confirm(`Are you sure you want to delete ${tenant.name} tenant?`)) {
        this.showLoadingModal = true;
        await axios.delete(`/espm_tenants/${tenant.id}`)
          .then(() => {
            this.tenants.splice(index, 1);
            this.updateSearchResultText();
            setTimeout(() => { this.showLoadingModal = false; }, 500);
          })
          .catch(() => {
            this.showLoadingModal = false;
            alert('Something went wrong. Please reach out to admin@sitelynx.net for support.');
          });
      }
    },

    // Make a new tenant object to add values into before saving
    addTenant() {
      this.showEditButtons = false;
      this.tenants.push({ espm_property_id: this.property.id, square_footage: 0, new: true });
    },

    editTenant(tenant) {
      // Make a copy of values before edit
      this.tenantBeforeEdit = _cloneDeep(tenant);
      this.showEditButtons = false;
      tenant.edit = true; // eslint-disable-line  no-param-reassign
    },

    // Cancel the edit and go back to values before edit
    cancelUpdate(index) {
      this.tenants[index] = this.tenantBeforeEdit;
      this.newMeters = [];
      this.removeMeters = [];
      this.showEditButtons = true;
      this.tenantBeforeEdit = null;
      this.notice = [];
    },

    // Cancel creating a new tenant
    cancelCreate(index) {
      this.tenants.splice(index, 1);
      this.showEditButtons = true;
      this.notice = [];
    },

    // Remove meter from tenant
    removeMeter(tenant, meter, index) {
      tenant.meters.splice(index, 1);
      this.removeMeters.push(meter.id);
    },

    // Add meter to tenant
    addMeter() {
      if (!this.meters.length) {
        alert('There are no available meters to add to this tenant.');
      }
      if (this.newMeters.length < this.meters.length) {
        this.newMeters.push(null);
      }
    },

    // Show the add meter button when the tenant is in edit view
    showAddMeterBtn(tenant) {
      return tenant?.meters?.length < this.meters?.length;
    },

    // Update text that shows when there are no tenants for the property
    updateSearchResultText() {
      if (!this.tenants.length) {
        this.searchResultText = 'There are no tenants for this property.';
      }
    },

    // Calculate the total square footage of all tenants
    totalTenantSquareFootage() {
      let totalSquareFootage = 0;
      this.tenants.forEach((tenant) => {
        totalSquareFootage += tenant.square_footage;
      });
      return this.numberWithDelimiter(totalSquareFootage);
    },

    // Return the total square footage of the property
    totalPropertySquareFootage() {
      return this.numberWithDelimiter(this.property.gross_floor_area);
    },

    numberWithDelimiter(number) {
      return Intl.NumberFormat().format(Math.round(number));
    },
  },
};
</script>
